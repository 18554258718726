import React from 'react';

import { Route, Redirect } from 'react-router-dom';

export default ({ render: C, props: childProps, userTypes, ...rest }) => (
  <Route
    {...rest}
    render={(rProps) =>
      true ? (
        <C {...rProps} {...childProps} />
      ) : (
        <Redirect to={{ pathname: `/login`, replace: true }} />
      )
    }
  />
);
