import React from 'react';
import { Switch, Route } from 'react-router-dom';
import ProtectedRoute from './components/ProtectedRoute';
import ClothingSampler from './pages/ClothingSampler';
import Home from './pages/Home';
import Resume from './pages/Resume';
import PdfTest from './pages/PdfTest';
import ReactionTest from './pages/ReactionTest';
import ProjectEstimator from './pages/ProjectEstimator';

const Routes = ({ childProps }) => (
  <Switch>
    <ProtectedRoute
      exact
      path="/cs"
      render={ClothingSampler}
      props={childProps}
    />
    <ProtectedRoute exact path="/" render={Home} props={childProps} />
    <ProtectedRoute exact path="/home" render={Home} props={childProps} />
    <ProtectedRoute exact path="/resume" render={Resume} props={childProps} />
    <ProtectedRoute
      exact
      path="/reaction-test"
      render={ReactionTest}
      props={childProps}
    />
    <ProtectedRoute
      exact
      path="/project-estimator"
      render={ProjectEstimator}
      props={childProps}
    />
    <ProtectedRoute exact path="/pdf" render={PdfTest} props={childProps} />
    <Route exact path="/error" render={() => <div> Error Page </div>} />
  </Switch>
);

export default Routes;
