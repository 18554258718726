import React, { useState } from 'react';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import BuildIcon from '@mui/icons-material/Build';
import DarkMode from '@mui/icons-material/DarkMode';
import LightMode from '@mui/icons-material/LightMode';
import BookIcon from '@mui/icons-material/Book';
import HomeOutlined from '@mui/icons-material/HomeOutlined';
import Speed from '@mui/icons-material/Speed';
import ListItem from '@mui/material/ListItem';
import RequestQuote from '@mui/icons-material/RequestQuote';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import { useGeneralContext } from '../context/GeneralContext';

const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);

export default function Header(props) {
  const [openDrawer, setOpenDrawer] = useState(false);
  const { darkTheme, dispatchTheme } = useGeneralContext();

  const handleDrawerOpen = () => {
    setOpenDrawer(!openDrawer);
  };

  const renderLink = React.forwardRef((itemProps, ref) => (
    // with react-router-dom@^5.0.0 use `ref` instead of `innerRef`
    <RouterLink
      to={props.to}
      {...itemProps}
      // activeclassname={classes.active}
      innerRef={ref}
    />
  ));

  const drawData = [
    { label: 'Home', route: '', tooltip: 'View home', icon: <HomeOutlined /> },
    {
      label: 'Resume',
      route: 'resume',
      tooltip: 'View resume',
      icon: <BookIcon />,
    },
    {
      title: 'Demos',
      label: 'Reaction',
      route: 'reaction-test',
      tooltip: 'View reaction test',
      icon: <Speed />,
    },
    {
      label: 'Estimator',
      route: 'project-estimator',
      tooltip: 'View project estimator',
      icon: <RequestQuote />,
    },
  ];

  const drawerItems = drawData.map((s) => (
    <>
      {s.title && (
        <ListItem>
          <ListItemText sx={{ textAlign: 'center' }} primary={s.title} />
        </ListItem>
      )}
      <Tooltip title={s.tooltip} placement="left">
        <ListItem
          button
          key={s.label}
          component={renderLink}
          to={s.route}
          onClick={() => handleDrawerOpen()}
        >
          <ListItemIcon>{s.icon}</ListItemIcon>
          <ListItemText primary={s.label} />
        </ListItem>
      </Tooltip>
    </>
  ));

  return (
    <>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => handleDrawerOpen()}
          >
            <MenuIcon />
          </IconButton>
          <Typography>James Sapsford</Typography>
        </Toolbar>
      </AppBar>
      <SwipeableDrawer
        sx={{ width: '10rem' }}
        anchor="left"
        open={Boolean(openDrawer)}
        onClose={() => handleDrawerOpen()}
        onOpen={() => setOpenDrawer(true)}
      >
        <List>
          {drawerItems}

          <Tooltip
            title={darkTheme ? 'Change to light mode' : 'Change to dark mode'}
          >
            <ListItem
              button
              key="theme"
              onClick={() => dispatchTheme(darkTheme)}
            >
              <ListItemIcon>
                {darkTheme ? <DarkMode /> : <LightMode />}
              </ListItemIcon>

              <ListItemText primary={darkTheme ? 'Dark' : 'Light'} />
            </ListItem>
          </Tooltip>
        </List>
      </SwipeableDrawer>
      <Offset />
    </>
  );
}
