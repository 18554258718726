import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { StyledEngineProvider } from '@mui/material/styles';
import App from './App';
import CollectiveContexts from './context/CollectiveContexts';

ReactDOM.render(
  <StyledEngineProvider injectFirst>
    <Router>
      <CollectiveContexts>
        <App />
      </CollectiveContexts>
    </Router>
  </StyledEngineProvider>,
  document.getElementById('root')
);
