import React, { useState } from 'react';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import format from 'date-fns/format';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import Masonry from '@mui/lab/Masonry';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import person from '../assets/person.png';
import styles from './Home.module.css';
import { useGeneralContext } from '../context/GeneralContext';

const css = `
.cardHeader {}`;

const todayDate = () => format(new Date(), 'cccc do MMMM yyyy');

const itemData = [
  {
    img: 'https://images.unsplash.com/photo-1518756131217-31eb79b20e8f',
    title: 'Fern',
  },
  {
    img: 'https://images.unsplash.com/photo-1627308595229-7830a5c91f9f',
    title: 'Snacks',
  },
  {
    img: 'https://images.unsplash.com/photo-1597645587822-e99fa5d45d25',
    title: 'Mushrooms',
  },
  {
    img: 'https://images.unsplash.com/photo-1529655683826-aba9b3e77383',
    title: 'Tower',
  },
  {
    img: 'https://images.unsplash.com/photo-1471357674240-e1a485acb3e1',
    title: 'Sea star',
  },
  {
    img: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
    title: 'Honey',
  },
  {
    img: 'https://images.unsplash.com/photo-1516802273409-68526ee1bdd6',
    title: 'Basketball',
  },
  {
    img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
    title: 'Breakfast',
  },
  {
    img: 'https://images.unsplash.com/photo-1627328715728-7bcc1b5db87d',
    title: 'Tree',
  },
  {
    img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
    title: 'Burger',
  },
  {
    img: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
    title: 'Camera',
  },
  {
    img: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
    title: 'Coffee',
  },
  {
    img: 'https://images.unsplash.com/photo-1627000086207-76eabf23aa2e',
    title: 'Camping Car',
  },
  {
    img: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
    title: 'Hats',
  },
  {
    img: 'https://images.unsplash.com/photo-1567306301408-9b74779a11af',
    title: 'Tomato basil',
  },
  {
    img: 'https://images.unsplash.com/photo-1627328561499-a3584d4ee4f7',
    title: 'Mountain',
  },
  {
    img: 'https://images.unsplash.com/photo-1589118949245-7d38baf380d6',
    title: 'Bike',
  },
];

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

export default function AssessmentOverview() {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const { theme } = useGeneralContext();

  // eslint-disable-next-line no-shadow
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  return (
    <Container>
      <Card color="primary">
        <Box sx={{ maxHeight: '40rem', overflowY: 'scroll' }}>
          <Masonry columns={3} spacing={1} defaultHeight={100}>
            {itemData.map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Stack key={index}>
                <img
                  src={`${item.img}?w=162&auto=format`}
                  srcSet={`${item.img}?w=162&auto=format&dpr=2 2x`}
                  alt={item.title}
                  loading="lazy"
                  style={{
                    borderBottomLeftRadius: 4,
                    borderBottomRightRadius: 4,
                  }}
                />
              </Stack>
            ))}
          </Masonry>
        </Box>

        <CardHeader
          className={styles.test}
          title="James Sapsford"
          // eslint-disable-next-line no-useless-concat
          subheader={todayDate()}
          titleTypographyProps={{
            fontSize: '1.5rem',
          }}
          avatar={
            <>
              <Avatar
                sx={{ bgcolor: '#ddd', height: '70px', width: '70px' }}
                aria-label="recipe"
                src={person}
              />
              {/* <Typography sx={{ marginLeft: '16px' }} variant="h3">
                James Sapsford <LinkedInIcon />
              </Typography> */}
            </>
          }
        />

        <CardContent>
          <Typography>
            On the other hand, we denounce with righteous indignation and
            dislike men who are so beguiled and demoralized by the charms of
            pleasure of the moment, so blinded by desire, that they cannot
            foresee the pain and trouble that are bound to ensue; and equal
            blame belongs to those who fail in their duty through weakness of
            will, which is the same as saying through shrinking from toil and
            pain. These cases are perfectly simple and easy to distinguish. In a
            free hour, when our power of choice is untrammelled and when nothing
            prevents our being able to do what we like best, every pleasure is
            to be welcomed and every pain avoided. But in certain circumstances
            and owing to the claims of duty or the obligations of business it
            will frequently occur that pleasures have to be repudiated and
            annoyances accepted. The wise man therefore always holds in these
            matters to this principle of selection: he rejects pleasures to
            secure other greater pleasures, or else he endures pains to avoid
            worse pains.
          </Typography>
          <CardActions>
            <Tooltip title="LinkedIn Profile">
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
                onClick={() =>
                  window.open(
                    'https://www.linkedin.com/in/jamessapsford/',
                    '_blank'
                  )
                }
              >
                <LinkedInIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Twitter Profile">
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
                onClick={() =>
                  window.open('https://twitter.com/justeatsugar/', '_blank')
                }
              >
                <TwitterIcon />
              </IconButton>
            </Tooltip>
          </CardActions>
        </CardContent>
      </Card>
    </Container>
  );
}
