import React, {
  useState,
  useEffect,
  createContext,
  useReducer,
  useContext,
} from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ThemeReducer } from './GeneralReducer';

export const GeneralContext = createContext({});

export function useGeneralContext() {
  return useContext(GeneralContext);
}

const GeneralContextProvider = (props) => {
  const [darkTheme, dispatchTheme] = useReducer(ThemeReducer, false);
  const theme = createTheme(
    darkTheme
      ? {
          palette: {
            mode: 'dark',
            primary: {
              main: '#bd93f9',
            },
            secondary: {
              main: '#ff4',
            },
            body: { backgroundColor: '#282a36' },
          },
        }
      : {
          palette: {
            mode: 'light',
            primary: {
              main: '#800080',
            },
            secondary: {
              main: '#ff4',
            },
            body: { backgroundColor: '#FFFF' },
          },
        }
  );

  const onSelectMode = (mode) => {
    if (mode === 'dark') {
      dispatchTheme(false);
    } else dispatchTheme(true);
  };

  useEffect(() => {
    // Create listener
    window
      .matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', (e) =>
        onSelectMode(e.matches ? 'dark' : 'light')
      );

    onSelectMode(
      window.matchMedia('(prefers-color-scheme: dark)').matches
        ? 'dark'
        : 'light'
    );

    // Remove listener
    return () => {
      window
        .matchMedia('(prefers-color-scheme: dark)')
        .removeEventListener('change', () => {});
    };
  }, []);

  return (
    <GeneralContext.Provider value={{ theme, dispatchTheme, darkTheme }}>
      {/* eslint-disable-next-line react/destructuring-assignment */}
      {props.children}
    </GeneralContext.Provider>
  );
};

export default GeneralContextProvider;
