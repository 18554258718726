/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from 'react';
import { differenceInMilliseconds } from 'date-fns';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

let falseStart = false;
let attempts = 4;

function ReactionTest() {
  const [reactionReady, setReactionReady] = useState(null);
  const [buttonStatus, setButtonStatus] = useState('white');
  const [startTime, setStartTime] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [reactionTimes, setReactionTimes] = useState([]);

  function clear() {
    setButtonStatus('white');
    setReactionReady(false);
    falseStart = false;
  }

  function start(begin) {
    if (begin === 1) attempts = 4;
    clear();
    setButtonDisabled(false);
    setTimeout(() => {
      if (!falseStart) {
        setReactionReady(true);
        setButtonStatus('green');
        setStartTime(new Date());
      } else {
        falseStart = false;
        clear();
      }
    }, 2000);
  }

  const stop = () => {
    const milliseconds = differenceInMilliseconds(new Date(), startTime);
    console.log(`seconds ${milliseconds / 1000}`);
    setReactionTimes([...reactionTimes, ...[milliseconds]]);
    setTimeout(() => {
      clear();
      if (attempts > 0) {
        attempts--;
        start();
      } else setButtonDisabled(true);
    }, 1000);
  };

  function handleReaction() {
    if (reactionReady) {
      setButtonStatus('gold');
      stop();
    } else {
      setButtonStatus('red');
      falseStart = true;
      setButtonDisabled(true);
    }
  }

  return (
    <div className="App">
      <button
        type="button"
        style={{
          width: '100%',
          height: '600px',
          border: 'none',
          backgroundColor: buttonStatus,
          active: { hover: { not: ['disabled'] } },
        }}
        disabled={buttonDisabled}
        onClick={() => {
          handleReaction();
        }}
      >
        {buttonStatus === 'white' && buttonDisabled && (
          <>
            <Typography variant="h4">
              When the screen turns
              <div style={{ color: 'green' }}> green </div> press the screen as
              fast as possible to test your reaction
            </Typography>
            <Typography variant="h4">
              A sample of 5 tests will be done
            </Typography>
            <Typography variant="h5">
              The test will end if false started
            </Typography>
          </>
        )}
      </button>
      <Button
        variant="contained"
        color="primary"
        disabled={!buttonDisabled || falseStart}
        onClick={() => start(1)}
        fullWidth="true"
        sx={{ height: '36.5px' }}
      >
        {buttonDisabled ? 'Begin Reaction Test' : ''}
      </Button>
      <div>
        {reactionTimes.map((i, a) => {
          if (i === Math.min(...reactionTimes)) {
            return (
              <Typography id={a} style={{ backgroundColor: '#FAFAD2' }}>
                {i / 1000} seconds ★
              </Typography>
            );
          }
          return (
            <Typography id={a} color="primary" style={{}}>
              {' '}
              {i / 1000} seconds{' '}
            </Typography>
          );
        })}
      </div>
    </div>
  );
}

export default ReactionTest;
