import React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import BuildIcon from '@mui/icons-material/Build';
import CircleOutlined from '@mui/icons-material/CircleOutlined';
import School from '@mui/icons-material/School';
import EmojiObjects from '@mui/icons-material/EmojiObjects';
import HomeRepairService from '@mui/icons-material/HomeRepairService';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Person from '../assets/person.png';

export default function Resume() {
  return (
    <Container>
      <Card sx={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
        <CardHeader
          avatar={
            <Avatar
              aria-label="recipe"
              sx={{ bgcolor: '#ddd', height: '70px', width: '70px' }}
              src={Person}
            >
              R
            </Avatar>
          }
          titleTypographyProps={{
            fontSize: '1.5rem',
          }}
          title="James Sapsford"
        />
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ marginTop: '1rem' }}>
            <Typography>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras nec
              erat sit amet sapien lobortis tincidunt maximus nec nulla. Aliquam
              sagittis leo non lacus pellentesque, sed semper nunc dignissim.
              Fusce est ex, varius ut commodo vel, ultricies nec est. Morbi at
              nunc nibh. Duis fermentum ante vel nisi bibendum, sed posuere
              metus scelerisque. Suspendisse nec rhoncus nisl, at maximus est.
              Morbi erat ligula, ullamcorper eu est sit amet, porta vulputate
              est. Aenean metus ante, feugiat in mi sit amet, condimentum
              scelerisque libero. In augue arcu, pharetra eget augue eget,
              scelerisque feugiat leo.{' '}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: '1rem' }}>
            <Typography variant="h5">
              <BuildIcon fontSize="medium" /> Skills
            </Typography>
            <Typography>
              Javascript/Nodejs, Git, Linux, React.js, material ui framework,
              Vue.js, serverless framework, REST APIs, AWS, HTML, CSS,
              Solidworks
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: '1rem' }}>
            <Typography variant="h5">
              <School fontSize="medium" /> Education
            </Typography>
            <Typography>Diploma of software development</Typography>
            <Typography>Certificate III business Administration</Typography>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: '1rem' }}>
            <Typography variant="h5">
              <EmojiObjects fontSize="medium" /> Experience
            </Typography>
            <Typography>
              Software Developer - Refactor 2018 – Present
            </Typography>
            <Typography>Full Stack developer</Typography>
            <Typography>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <CircleOutlined fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Node.js" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CircleOutlined fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Reacts.js" />
                </ListItem>
              </List>
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: '1rem', marginBottom: '1rem' }}>
            <Typography variant="h5">
              <HomeRepairService fontSize="medium" /> Projects
            </Typography>
            <Typography>
              Maecenas ut rhoncus lorem, sed posuere ex. Aliquam mollis
              convallis turpis, at maximus lectus tempus in. In hac habitasse
              platea dictumst. Curabitur non iaculis turpis. Vivamus tincidunt
              dui vel vulputate ornare. Cras varius dui at lectus tempus
              lacinia. Maecenas dignissim leo in metus laoreet dapibus. Sed
              lobortis ante sed tortor congue vestibulum. Vivamus nisi dolor,
              lobortis a molestie sed, faucibus vel leo. Curabitur ullamcorper
              porta libero, ac malesuada elit eleifend quis. Suspendisse
              placerat ullamcorper eros, eget consequat justo pretium ac.
              Curabitur eleifend eu orci sit amet euismod. Suspendisse potenti.
            </Typography>
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
}
