import React, { useState } from 'react';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Slider, { SliderThumb } from '@mui/material/Slider';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useTheme } from '@mui/styles';

import Star from '@mui/icons-material/Star';
import StarHalf from '@mui/icons-material/StarHalf';
import StarBorder from '@mui/icons-material/StarBorder';
import AccessTime from '@mui/icons-material/AccessTime';
import Monetization from '@mui/icons-material/MonetizationOn';

export default function ProjectEstimator() {
  const [test, setTest] = useState({
    quality: true,
    speed: true,
    price: false,
  });
  const [points, setPoints] = useState({
    quality: 100,
    speed: 50,
    price: 50,
  });

  const [tabValue, setTabValue] = React.useState('basic');
  const theme = useTheme();

  const handleTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleChange = (e) => {
    if (JSON.parse(e.value)) {
      setTest({ ...test, ...{ [e.id]: false } });
      test[e.id] = false;
    } else if (Object.values(test).filter((v) => v === true).length < 2) {
      setTest({ ...test, ...{ [e.id]: true } });
    } else {
      let newArr = { ...test };
      delete newArr[[e.id]];
      newArr = Object.keys(newArr);

      const randomBool = Math.random() < 0.5;
      if (randomBool) {
        setTest({ ...test, ...{ [newArr[0]]: false, [e.id]: true } });
      } else setTest({ ...test, ...{ [newArr[1]]: false, [e.id]: true } });
    }
  };

  const oneOrZero = Math.random() < 0.5 ? 0 : 1;

  const handleSlide = (e) => {
    if (
      Object.values(points).reduce((acc, val) => acc + val, 0) >= 200 &&
      points[e.name] < e.value
    ) {
      const filteredArray = Object.keys(points).filter((i) => i !== e.name);

      const minus = points[e.name] - e.value;

      const newValue = points[filteredArray[oneOrZero]] + minus;

      setPoints({
        ...points,
        ...{ [filteredArray[oneOrZero]]: newValue, [e.name]: e.value },
      });
    } else setPoints({ ...points, ...{ [e.name]: e.value } });
  };

  const handleSlideCommitted = (e) => {
    console.log(e);
  };

  const Title = ({ value, align }) => (
    <Typography align={align || 'left'} sx={{ paddingTop: '2rem' }}>
      {value}
    </Typography>
  );

  function goodThumb(props) {
    const { children, ...other } = props;
    return (
      <SliderThumb {...other}>
        {children}
        {points.quality >= 80 && <Star fontSize="large" color="secondary" />}
        {points.quality < 79 && points.quality >= 45 && (
          <StarHalf fontSize="large" color="secondary" />
        )}
        {points.quality < 44 && (
          <StarBorder fontSize="large" color="secondary" />
        )}
      </SliderThumb>
    );
  }
  function PriceThumb(props) {
    const { children, ...other } = props;
    return (
      <SliderThumb {...other}>
        {children}
        {points.price >= 80 && (
          <Monetization fontSize="small" color="secondary" />
        )}
        {points.price < 79 && points.price >= 45 && (
          <Monetization fontSize="medium" color="secondary" />
        )}
        {points.price < 44 && (
          <Monetization fontSize="large" color="secondary" />
        )}
      </SliderThumb>
    );
  }
  function speedThumb(props) {
    const { children, ...other } = props;
    return (
      <SliderThumb {...other}>
        {children}
        {points.speed >= 80 && (
          <AccessTime fontSize="small" color="secondary" />
        )}
        {points.speed < 79 && points.speed >= 45 && (
          <AccessTime fontSize="medium" color="secondary" />
        )}
        {points.speed < 44 && <AccessTime fontSize="large" color="secondary" />}
      </SliderThumb>
    );
  }

  const basicSwitchData = [
    {
      left: 'Ok',
      right: 'Good',
      id: 'quality',
      title: 'Project Quality',
      value: test.quality,
      checked: test.quality,
    },
    {
      left: 'Slow',
      right: 'Fast',
      id: 'speed',
      title: 'Project Completion Rate',
      value: test.speed,
      checked: test.speed,
    },
    {
      left: 'Expensive',
      right: 'Cheap',
      id: 'price',
      title: 'Project Expenditure',
      value: test.price,
      checked: test.price,
    },
  ];

  const advancedSwitchData = [
    {
      left: 'Ok',
      right: 'Good',
      id: 'quality',
      title: 'Project Quality',
      value: points.quality,
      thumb: goodThumb,
    },
    {
      left: 'Slow',
      right: 'Fast',
      id: 'speed',
      title: 'Project Completion Rate',
      value: points.speed,
      thumb: speedThumb,
    },
    {
      left: 'Expensive',
      right: 'Cheap',
      id: 'price',
      title: 'Project Expenditure',
      value: points.price,
      thumb: PriceThumb,
    },
  ];

  return (
    <Container>
      <Card
        sx={{
          paddingLeft: '1rem',
          paddingRight: '1rem',
          paddingBottom: '10rem',
        }}
      >
        <CardHeader
          title="Project Estimator"
          subheader="Helping you pick a path"
        />

        <Typography align="center" sx={{ paddingTop: '1rem' }} variant="h6">
          The following will help you to determine in which vectors you should
          prioritize
        </Typography>

        <Box sx={{ width: '100%', paddingBottom: '2rem', paddingTop: '1rem' }}>
          <Tabs
            value={tabValue}
            onChange={handleTab}
            textColor="contrast"
            indicatorColor="primary"
            aria-label="secondary tabs example"
            variant="fullWidth"
          >
            <Tab
              value="basic"
              label="Basic"
              sx={{
                backgroundColor:
                  tabValue === 'basic' ? theme.palette.primary.main : 'fffffff',
              }}
            />
            <Tab
              sx={{
                backgroundColor:
                  tabValue === 'advanced'
                    ? theme.palette.primary.main
                    : 'fffffff',
              }}
              value="advanced"
              label="Advanced"
            />
          </Tabs>
        </Box>

        {tabValue === 'basic' && (
          <Box
            sx={{
              width: '10rem',
              marginLeft: 'auto',
              marginRight: 'auto',
              paddingBottom: '2rem',
            }}
          >
            <FormGroup>
              {basicSwitchData.map((b) => (
                <>
                  <Title value={b.title} />
                  <Stack
                    justifyContent="center"
                    direction="row"
                    spacing={1}
                    alignItems="center"
                  >
                    <Typography align="right" sx={{ width: '5rem' }}>
                      {b.left}
                    </Typography>
                    <Switch
                      id={b.id}
                      checked={b.checked}
                      value={b.value}
                      onChange={(e) => handleChange(e.target)}
                      defaultChecked
                    />
                    <Typography>{b.right}</Typography>
                  </Stack>
                </>
              ))}
            </FormGroup>
          </Box>
        )}

        {tabValue === 'advanced' && (
          <>
            {advancedSwitchData.map((d) => (
              <>
                <Title value={d.title} align="center" />
                <Stack
                  justifyContent="center"
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  sx={{ paddingBottom: '1rem' }}
                >
                  <Typography
                    align="right"
                    sx={{ width: '5rem', paddingRight: '1rem' }}
                  >
                    {d.left}
                  </Typography>
                  <Slider
                    size="small"
                    name={d.id}
                    value={d.value}
                    aria-label="Small"
                    valueLabelDisplay="off"
                    onChange={(e) => {
                      handleSlide(e.target);
                    }}
                    onChangeCommitted={(e) => {
                      handleSlideCommitted(e.target);
                    }}
                    components={{ Thumb: d.thumb }}
                    sx={{
                      '& .MuiSlider-thumb': { color: 'transparent' },
                      width: '40%',
                    }}
                  />
                  <Typography sx={{ width: '5rem', paddingLeft: '1rem' }}>
                    {d.right}
                  </Typography>
                </Stack>
              </>
            ))}
          </>
        )}
      </Card>
    </Container>
  );
}
